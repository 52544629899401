<template>
  <UiTourDriver
    ref="driver"
    :highlight
    :tour="TOURS_IDS.GLOBAL_SEARCH"
    :steps
    @destroyed="useMittEvent('tour:clearGlobalSearchMock')"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS } from '~/constants'

const highlight = ref<DriveStep>({
  element: '#global-search-field',
  popover: {
    align: 'start',
    side: 'bottom',
    title: 'New Feature!',
    description: `We’ve released a new feature. Review the mini-tour of its usage now or find it later in the support center.`,
  },
})

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

const steps = computed<DriveStep[]>(() => [
  {
    element: '#global-search-field',
    popover: {
      title: '1/6 Let’s search!',
      description: `No more toggling between Pipeline, Deals, and Lead Pages for your searches. Now, you can harness the power of global search.`,
      align: 'start',
      side: 'bottom',
      onNextClick: async () => {
        useMittEvent('tour:setGlobalSearchMock')
        await nextTick()
        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#global-search',
    popover: {
      title: '2/6 All results',
      description: `Under the "All" tab, you'll find a comprehensive list including names, contacts, and snippets of notes. Clicking on the Leads/Deals tab narrows down results to only those relevant to that category.`,
      popoverClass: '!mt-12',
      onPrevClick: async () => {
        useMittEvent('tour:clearGlobalSearchMock')
        await nextTick()
        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
    },
  },
  {
    element: '#search-card-4',
    popover: {
      title: '3/6 Search result row',
      description: `Clicking on a search result row opens it. Additionally, you can copy the link to the search result without navigating inside`,
    },
  },
  {
    element: '#toggle-search-filter',
    popover: {
      title: '4/6 Search filters',
      description: `Within the search bar, you'll find a filter panel. Expand it to access and apply various filters.`,
      align: 'end',
      side: 'bottom',
      onNextClick: async () => {
        const filterButton = document.querySelector<HTMLElement>('#toggle-search-filter')

        if (filterButton) {
          filterButton.click()
        }
        await nextTick()
        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#search-filters',
    popover: {
      title: '5/6 Search filters',
      description: `Enter your request and select a filter to refine your search results for greater specificity.`,
      onPrevClick: () => {
        const filterButton = document.querySelector<HTMLElement>('#toggle-search-filter')

        if (filterButton) {
          filterButton.click()
        }
        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: async () => {
        const filterButton = document.querySelector<HTMLElement>('#display-only-notes')

        if (filterButton) {
          filterButton.click()
        }
        await nextTick()
        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#search-content',
    popover: {
      title: '6/6 Search results',
      description: `Enjoy the filtered search results and the new feature in the CRM!`,
      popoverClass: '!mt-[70px]',
      onPrevClick: () => {
        const filterButton = document.querySelector<HTMLElement>('#display-only-')

        if (filterButton) {
          filterButton.click()
        }

        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: () => {
        if (driver.value?.driverObj) {
          driver.value.driverObj.destroy()
        }
      },
    },
  },
])
</script>

<style scoped></style>
